define("ember-test-selectors/utils/bind-data-test-attributes", ["exports", "@ember/debug", "@ember/array"], function (_exports, _debug, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bindDataTestAttributes;
  var TEST_SELECTOR_PREFIX = /data-test-.*/;

  function bindDataTestAttributes(component) {
    var dataTestProperties = [];

    for (var attr in component) {
      if (TEST_SELECTOR_PREFIX.test(attr)) {
        dataTestProperties.push(attr);
      }
    }

    if (dataTestProperties.length === 0) {
      return;
    }

    var tagName = component.get('tagName');

    if (component.get('supportsDataTestProperties') && tagName === '') {
      return;
    }

    var message = "ember-test-selectors could not bind data-test-* properties on ".concat(component, " ") + "automatically because tagName is empty. If you did this intentionally, see " + "https://github.com/simplabs/ember-test-selectors#usage-in-computed-properties " + "for instructions on how to disable this assertion.";
    (true && !(tagName !== '') && (0, _debug.assert)(message, tagName !== '', {
      id: 'ember-test-selectors.empty-tag-name'
    }));
    var attributeBindings = component.get('attributeBindings') || [];

    if (!(0, _array.isArray)(attributeBindings)) {
      attributeBindings = [attributeBindings];
    } else {
      attributeBindings = attributeBindings.slice();
    }

    for (var _i = 0, _dataTestProperties = dataTestProperties; _i < _dataTestProperties.length; _i++) {
      var prop = _dataTestProperties[_i];

      if (attributeBindings.indexOf(prop) === -1) {
        var componentName = extractComponentName(component) || "<unknown>";
        (true && !(false) && (0, _debug.deprecate)("You have set ".concat(prop, " on the ").concat(componentName, " component. Relying on automatic attribute binding of data-test properties on classic components is deprecated. Your options are:\n\n") + '- use angle bracket syntax with `...attributes` to invoke components\n' + '- explicitly add `attributeBindings` to the component\n' + '- stay on an older version of ember-test-selectors\n\n', false, {
          for: 'ember-test-selectors',
          id: 'ember-test-selectors.auto-binding',
          until: '6.0.0',
          since: {
            available: '5.2.0',
            enabled: '5.2.0'
          }
        }));
        attributeBindings.push(prop);
      }
    }

    try {
      component.set('attributeBindings', attributeBindings);
    } catch (error) {
      var _message = "ember-test-selectors could not bind data-test-* properties on ".concat(component, " ") + "automatically because \"attributeBindings\" is a read-only property.";

      (true && !(false) && (0, _debug.assert)(_message, false, {
        id: 'ember-test-selectors.computed-attribute-bindings'
      }));
    }
  }

  function extractComponentName(component) {
    var debugKey = component._debugContainerKey;

    if (debugKey) {
      return debugKey.replace(/^component:/, '');
    }

    var className = component.constructor.name;

    if (className && className !== 'Class') {
      return className;
    }
  }
});