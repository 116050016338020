define("ember-maybe-in-element/components/maybe-in-element", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @renderInPlace}}{{yield}}{{else}}{{#in-element @destinationElement insertBefore=null}}{{yield}}{{/in-element}}{{/if}}
  */
  {
    "id": "J+2eIQg4",
    "block": "[[[41,[30,1],[[[18,3,null]],[]],[[[40,[[[18,3,null]],[]],\"%cursor:0%\",[28,[37,3],[[30,2]],null],null]],[]]]],[\"@renderInPlace\",\"@destinationElement\",\"&default\"],false,[\"if\",\"yield\",\"in-element\",\"-in-el-null\"]]",
    "moduleName": "ember-maybe-in-element/components/maybe-in-element.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});