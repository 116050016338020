define("ember-concurrency/helpers/cancel-all", ["exports", "@ember/component/helper", "@ember/debug", "ember-concurrency/-private/helpers"], function (_exports, _helper, _debug, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cancelHelper = cancelHelper;
  _exports.default = void 0;
  var CANCEL_REASON = "the 'cancel-all' template helper was invoked";

  function cancelHelper(args) {
    var cancelable = args[0];

    if (!cancelable || typeof cancelable.cancelAll !== 'function') {
      (true && !(false) && (0, _debug.assert)("The first argument passed to the `cancel-all` helper should be a Task or TaskGroup (without quotes); you passed ".concat(cancelable), false));
    }

    return (0, _helpers.taskHelperClosure)('cancel-all', 'cancelAll', [cancelable, {
      reason: CANCEL_REASON
    }]);
  }

  var _default = (0, _helper.helper)(cancelHelper);

  _exports.default = _default;
});