define("@ember-data/private-build-infra/index", ["exports", "require", "@ember-data/private-build-infra/available-packages"], function (_exports, _require, _availablePackages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HAS_STORE_PACKAGE = _exports.HAS_SERIALIZER_PACKAGE = _exports.HAS_RECORD_DATA_PACKAGE = _exports.HAS_MODEL_PACKAGE = _exports.HAS_EMBER_DATA_PACKAGE = _exports.HAS_DEBUG_PACKAGE = _exports.HAS_ADAPTER_PACKAGE = void 0;

  function flagState(flag) {
    var packageName = _availablePackages.default[flag];
    return (0, _require.has)(packageName) || false;
  }

  var HAS_EMBER_DATA_PACKAGE = flagState('HAS_EMBER_DATA_PACKAGE');
  _exports.HAS_EMBER_DATA_PACKAGE = HAS_EMBER_DATA_PACKAGE;
  var HAS_STORE_PACKAGE = flagState('HAS_STORE_PACKAGE');
  _exports.HAS_STORE_PACKAGE = HAS_STORE_PACKAGE;
  var HAS_MODEL_PACKAGE = flagState('HAS_MODEL_PACKAGE');
  _exports.HAS_MODEL_PACKAGE = HAS_MODEL_PACKAGE;
  var HAS_ADAPTER_PACKAGE = flagState('HAS_ADAPTER_PACKAGE');
  _exports.HAS_ADAPTER_PACKAGE = HAS_ADAPTER_PACKAGE;
  var HAS_SERIALIZER_PACKAGE = flagState('HAS_SERIALIZER_PACKAGE');
  _exports.HAS_SERIALIZER_PACKAGE = HAS_SERIALIZER_PACKAGE;
  var HAS_DEBUG_PACKAGE = flagState('HAS_DEBUG_PACKAGE');
  _exports.HAS_DEBUG_PACKAGE = HAS_DEBUG_PACKAGE;
  var HAS_RECORD_DATA_PACKAGE = flagState('HAS_RECORD_DATA_PACKAGE');
  _exports.HAS_RECORD_DATA_PACKAGE = HAS_RECORD_DATA_PACKAGE;
});